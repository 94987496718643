define("creators-web-app/locales/en/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint no-template-curly-in-string: "off" */
  var shared = {
    defaultStateLabel: '{kind} is {aasmState}'
  };
  var _default = {
    errors: {
      accepted: '{description} must be accepted',
      after: '{description} must be after {after}',
      arrayTooLong: 'Too many {description} (maximum is {max})',
      arrayTooShort: 'Not enough {description} (minimum is {min})',
      arrayWrongLength: 'Incorrect number of {description} (required amount is {is})',
      before: '{description} must be before {before}',
      blank: "{description} can't be blank",
      collection: '{description} must be a collection',
      confirmation: "{description} doesn't match {on}",
      date: '{description} must be a valid date',
      description: 'This field',
      email: '{description} must be a valid email address',
      empty: "{description} can't be empty",
      equalTo: '{description} must be equal to {is}',
      even: '{description} must be even',
      exclusion: '{description} is reserved',
      fileSize: 'File size must be under {maxFileSize}',
      fileType: "No can do. We don't support that file type.",
      general: 'Sorry, something went wrong. Please try again or email support@popularpays.zendesk.com',
      greaterThan: '{description} must be greater than {gt}',
      greaterThanOrEqualTo: '{description} must be greater than or equal to {gte}',
      inclusion: '{description} is not included in the list',
      invalid: '{description} is invalid',
      lessThan: '{description} must be less than {lt}',
      lessThanOrEqualTo: '{description} must be less than or equal to {lte}',
      notAnInteger: '{description} must be an integer',
      notANumber: '{description} must be a number',
      odd: '{description} must be odd',
      otherThan: '{description} must be other than {value}',
      phone: '{description} must be a valid phone number',
      positive: '{description} must be positive',
      present: '{description} must be blank',
      singular: "{description} can't be a collection",
      tooLong: '{description} is too long (maximum is {max} characters)',
      tooShort: '{description} is too short (minimum is {min} characters)',
      url: '{description} must be a valid url',
      wrongDateFormat: '{description} must be in the format of {format}',
      wrongLength: '{description} is the wrong length (should be {is} characters)'
    },
    global: {
      buttons: {
        cancel: 'Cancel',
        delete: 'Delete',
        edit: 'Edit',
        login: 'Sign in',
        logout: 'Sign out',
        print: 'Print',
        undo: 'Undo',
        replace: 'Replace'
      },
      'content-creation': 'Content',
      'follower-count': '<strong>{unlocksAt}+</strong> followers',
      'network-posts-text': '{network} {count, plural, one {Post} other {Posts}}',
      'network-reels-text': '{network} Reels {count, plural, one {Post} other {Posts}}',
      'network-stories-text': '{network} Story {count, plural, one {Post} other {Posts}}',
      'relink-account': {
        text: 'Reconnect your {network} Account.',
        relink: 'Reconnect'
      },
      'save-error': 'Shoot! An error occurred and we cannot save your info.',
      'success-message': 'Locked and loaded. Keep it up, champ',
      'upload-error': 'Upload Failed'
    },
    components: {
      'filters-popup': {
        'action-buttons': {
          reset: 'Clear all filters',
          show: 'Show {total} Gigs'
        },
        sections: {
          deliverables: {
            title: 'Deliverables',
            checkboxes: {
              photos: 'Photo',
              videos: 'Video'
            }
          },
          'social-networks': {
            title: 'Social Networks',
            subtitle: 'To apply for a gig on a specific platform, you’ll first need to connect your account.',
            sliders: {
              title: 'Number of followers'
            }
          },
          compensation: {
            title: 'Compensation',
            checkboxes: {
              fixed: 'Fixed',
              negotiable: 'Negotiable'
            },
            sliders: {
              title: 'How much do you want to be paid?'
            }
          },
          'vertical-tags': {
            title: 'Tags',
            'show-more': 'Show more',
            'show-less': 'Show less'
          }
        }
      },
      'account-management-actions': {
        download: {
          button: 'Download all of my data',
          description: "This comprehensive download will contain information like your profile, portfolio, applications, campaigns you've completed, and more."
        },
        deactivate: {
          button: 'Deactivate my account',
          description: "You can deactivate your account once all your Gigs are completed or canceled. <a href='https://popularpays.zendesk.com/hc/en-us/articles/360037578012-How-do-I-deactivate-my-account-' rel='noopener noreferrer' target='_blank'>Deactivating your account</a> will disable your profile and will hide your data from people on the platform.",
          modal: {
            'cancel-button': 'Cancel',
            'confirm-button': 'Confirm Deactivation',
            description: 'Deactivating your account will disable your profile and will hide your data from people on the platform. Deactivating will automatically log you out and your account will remain deactivated until you log in again.',
            header: 'Deactivating Your Account'
          }
        },
        delete: {
          button: 'Delete my account',
          description: "You can only delete your account if you don't have any pending or completed Gigs. <a href='https://popularpays.zendesk.com/hc/en-us/articles/360037578012-How-do-I-deactivate-my-account-' rel='noopener noreferrer' target='_blank'>When you delete your account</a>, your data will be removed from the platform entirely. This cannot be undone.",
          modal: {
            'cancel-button': 'Cancel',
            'confirm-button': 'Confirm Deletion',
            description: '“When you delete your account, any identifiers connecting you and the data on the platform will be removed from the platform.',
            header: 'Deleting Your Account'
          }
        }
      },
      'address-form': {
        'address-label': 'Address',
        buttons: {
          cancel: 'Cancel'
        },
        'city-label': 'City',
        'country-label': 'Country',
        'region-label': 'State/Province',
        'postal-code-label': 'Postal Code',
        'state-label': 'State/Province'
      },
      'address-input': {
        body: "We don't know where to ship the product if you are chosen for the campaign. A little help?",
        buttons: {
          add: 'Add',
          'add-shipping': 'Add shipping address'
        },
        title: 'Where are you located?'
      },
      'bank-account-display': {
        buttons: {
          delete: 'Delete'
        },
        'change-payment': 'Change payment method',
        tooltip: 'Updating your payment method won\'t affect payments that have moved to "Payment in progress".'
      },
      'bank-account-form': {
        'account-number': 'Account Number (7-17 Digits)',
        'account-type': 'Account Type',
        'first-name': 'First Name',
        'last-name': 'Last Name',
        'routing-number': 'Routing Number (9 Digits)'
      },
      'brief-item-promotable-url': {
        title: 'Custom URL for Posting',
        content: 'You must link to the URL shown per the gig instructions.',
        'highlighted-no-url': 'URL will be shown here if you are accepted into this campaign.'
      },
      'brief-item-discount-code': {
        title: 'Coupon Code For Posting',
        content: 'You must include the discount code with your post as specified below',
        'highlighted-no-code': 'Discount code will be shown here if you are accepted into this campaign.'
      },
      'campaign-card': {
        'follower-count': '<strong>{unlocksAt}+</strong> followers',
        'objective-percent': 'Hurry! Campaign is {percent}% full',
        'recruited-text': 'Gig for you'
      },
      'collaboration-card': {
        'follower-count': '<strong>{unlocksAt}+</strong> followers',
        'objective-percent': 'Hurry! Campaign is {percent}% full',
        'recruited-text': 'Gig for you'
      },
      'card-frame': {
        followers: 'Followers',
        deliverables: 'Deliverables',
        'not-required': 'Not Required',
        'flexible-fee': 'Suggested Fee',
        'fixed-fee': 'Fixed Fee',
        bid: 'Bid'
      },
      'campaign-compensation': {
        'compensation-text': 'compensation',
        price: '{price, number, USD}',
        'suggested-bid-text': 'compensation range',
        'suggested-at-least': '{price, number, USD} or more',
        'suggested-up-to': 'Up to {price, number, USD}'
      },
      'campaign-deliverables': {
        header: 'What deliverables am I producing?',
        'network-post-text': '{network} Post',
        'network-reels-text': '{network} Reels Post',
        'network-story-text': '{network} Story Post'
      },
      'campaign-dismissal-dialog': {
        actions: {
          cancel: 'Cancel',
          dismiss: 'Hide this gig'
        },
        'dismissal-reasons': {
          fit: "Doesn't fit with my feed / brand",
          other: 'Other reason',
          price: 'Price',
          relevancy: "Don't meet the gig requirements",
          timing: 'Timing / Scheduling'
        },
        'other-placeholder': 'reason...',
        'sub-text': "You won't see this gig again. Let us know why it's not for you.",
        title: 'Not interested?'
      },
      'campaign-eligibility': {
        header: 'Am I eligible?',
        requirements: {
          'follower-count': '{unlocksAt}+ followers'
        },
        subheader: 'You must meet these requirements:'
      },
      'campaign-preferred-creator': {
        header: 'Am I a good fit?',
        requirements: {
          capability: 'With Capabilities:',
          specialty: 'Specializing in:'
        },
        subheader: 'The brand is looking for creators that fit this profile:',
        'warning-tooltip': 'The brand is looking for creators in a different location than you.'
      },
      'campaign-list': {
        'empty-list-title': "We don't have any gigs for you right now.",
        'empty-list-body': "You don't have any gigs to apply for. Check out <a href='https://popularpays.zendesk.com/hc/en-us/articles/360037944031-My-app-says-there-are-no-gigs-for-me-right-now-Why-' target='_blank'>this Support article</a> if you have any questions."
      },
      'campaign-list-header': {
        title: 'Suggested Gigs',
        'sub-title': ' Gigs available',
        'filters-button': {
          caption: 'Filters'
        }
      },
      'campaign-tabs': {
        'all-header': 'All',
        'no-network-header': 'Content Creation',
        show: 'Show'
      },
      'campaign-out-of-zone-dialog': {
        title: "Heads up! Your location doesn't match.",
        description: {
          singular: "This brand is looking for people located specifically in the following location: <strong>{locations}</strong> - which doesn't match the location in your profile. You can still apply if you're willing and able to travel to the location listed above.",
          plural: "This brand is looking for people located specifically in at least one of the following locations: <strong>{locations}</strong> - none of which match the location in your profile. You can still apply if you're willing and able to travel to one of the locations listed above."
        },
        confirm: 'I understand!'
      },
      'campaign-creator-has-no-details-dialog': {
        title: 'You must have at least 4 portfolio items or connected social account',
        description: 'Either link a single social network or add at least 4 images to your portfolio',
        complete_images: 'Complete portfolio',
        complete_networks: 'Connect Social Account',
        or: 'or'
      },
      'campaign-profile-details-dialog': {
        social_network: {
          title: 'Connect social account'
        },
        portfolio: {
          title: 'Add Photos or Videos',
          description: 'Your portfolio must include at least 4 photo or video works',
          footer: 'The more items you add to your portfolio, the better!',
          button: 'Done'
        }
      },
      'content-submission-form': {
        'confirm-delete': 'Are you really sure you want to delete this {kind}?',
        'file-type-error': "No can do. We don't support that file type.",
        'file-size-error': "Sorry, we can't support {kind}s over {size}.",
        'choose-file': 'Add file',
        'choose-file-after-error': 'Add another file',
        'disclaimer-photos-to-post': 'Content and captions that <strong>have not yet been approved cannot be posted.</strong> You warrant that you have the necessary permissions for any of the content you upload, including but not limited to authorizations for any identifiable individuals, third party brand logos or markers, music, artwork, architecture, etc.',
        'disclaimer-content-creation': 'By uploading content with identifiable people, third party logos or brand markers, you warrant that you have permission to do so and all parties understand how the content may be used.',
        note: 'Note:',
        submit: 'Submit for brand review'
      },
      'conversation-list-item': {
        'no-message-text': 'No messages yet'
      },
      'email-form': {
        buttons: {
          update: 'Update Email'
        },
        label: 'Your Email'
      },
      'gig-card': {
        'awaiting-brand-body': {
          body: 'Brand is reviewing your content',
          title: 'Keep those posting fingers ready.'
        },
        'awaiting-payment-body': {
          body: 'Your payment is on its way.',
          title: 'Nice work on that Gig!'
        },
        'cancelled-body': {
          body: 'This Gig was cancelled by the Brand.'
        },
        'completed-body': {
          body: 'Completed!'
        },
        'expired-body': {
          body: 'This Gig is no longer active.',
          title: 'Gig has expired'
        },
        'proposed-body': {
          body: "They're deciding; stay tuned!",
          title: 'Brand is reviewing application'
        },
        'rejected-body': {
          body: 'Thanks for the submission, but the brand went with someone else :('
        },
        subheader: {
          'payment-info': 'Payment takes 1-4 days to process'
        }
      },
      'gig-form': {
        buttons: {
          submit: 'Submit my application for review'
        },
        description: 'Feeling inspired by this Gig? Get your application in.',
        'fixed-bid': 'Fixed Fee',
        'fixed-cost-note': "The price was set by the brand and can't be changed.",
        'get-paid': 'What would you want to be paid (USD)?',
        GDPR: {
          'consent-to-process': 'By submitting this application, you agree to share your profile & portfolio information with brands and other third parties to review.'
        },
        suggested: {
          'at-least': '{brand} has indicated that they are willing to pay {price, number, USD} or more to each successful applicant',
          'up-to': '{brand} has indicated that they are willing to pay up to {price, number, USD} to each successful applicant',
          'range-of-values': '{brand} has indicated that they are willing to pay {suggestedRange} to each successful applicant'
        },
        title: 'Apply for this Gig',
        tos: "I have read and agree to the Campaign Brief and <a href='https://popularpays.com/terms' target='_blank' rel='noopener' class='inline'>Creator Terms and Conditions of Service</a>"
      },
      'gig-page': {
        conversation: {
          buttons: {
            send: 'Send Message',
            'nav-to-support': 'Need help with Payments, Uploads or Platform Issues? <strong>Contact Support</strong>',
            'nav-to-brand': 'back to <strong>{name}</strong>'
          },
          'brand-empty': 'Talk to <strong>{name}</strong> about Gig related questions.',
          'support-empty': {
            title: 'How can we help?',
            body: 'We usually respond within one business day.'
          },
          note: 'A Brand might take up to 48 hrs to respond.',
          'q-and-a': 'Check out our FAQ',
          'unable-to-reply': "The conversation is closed because the Brand didn't accept your application.",
          title: {
            brand: 'Conversation with <strong>{name}</strong>',
            support: 'Popular Pays Support'
          }
        },
        navigation: {
          application: {
            title: 'Your Application'
          },
          'blogs-link': '{count, plural, one {Blog} other {Blogs}}',
          brief: {
            title: 'Gig Brief'
          },
          'due-date': {
            header: 'Deliverables',
            due: 'First upload due on',
            pending: 'Due date pending'
          },
          header: 'Information',
          'mixed-media-link': 'Story Uploads',
          'photos-link': '{count, plural, one {Photo} other {Photos}}',
          prep: {
            header: 'Prep',
            'gift-codes': 'Gift Code',
            shipping: 'Shipping'
          },
          'tab-count': {
            'accepted-tooltip': 'Accepted',
            'required-tooltip': 'Required'
          },
          'videos-link': '{count, plural, one {Video} other {Videos}}',
          'what-is-mixed-media': 'What are Story Uploads?',
          'tiktok-v2': {
            'order-status': {
              inactive: 'Order Inactive',
              active: 'Order Active',
              complete: 'Order Completed'
            }
          }
        },
        proposal: {
          'deadline-title': 'Upload content by',
          'get-paid-title': 'What would you want to be paid for this Gig?',
          'your-application': 'Your Application',
          'application-date': 'Application date: '
        }
      },
      'location-input': {
        placeholder: 'Your current city'
      },
      'login-form': {
        'email-label': 'Email',
        'email-placeholder': 'Enter your email',
        notes: "By clicking {submitText}, you acknowledge that you have read and agree to our <a href='https://popularpays.com/terms' target='_blank' rel='noopener noreferrer' class='inline'>Creator Terms and Conditions of Service</a>",
        'invalid-email-error': 'Please enter your email address',
        'empty-email-error': 'Please enter your email address',
        'terms-and-privacy-unchecked': 'Please confirm that you have read and agree to the Terms of use and Privacy policy'
      },
      'media-item': {
        'confirm-remove-text': 'Do you really want to delete this {kind}?',
        'deleted-message': 'Deleted',
        'processing-item-text': 'Processing {kind}',
        'view-text': 'View {kind}'
      },
      'media-upload': {
        'cancel-text': 'Cancel',
        'delete-text': 'Delete',
        'deleted-header-text': 'Deleted',
        header: {
          deleted: 'Deleted',
          'failed-upload': 'Upload Failed',
          processing: 'Processing {kind}'
        },
        'replace-text': 'Replace',
        'retry-text': 'Retry',
        'undo-text': 'Undo',
        'view-text': 'View {kind}'
      },
      'messages-list': {
        'no-messages-text': 'No messages found.',
        'new-messages-text': 'New Messages'
      },
      'message-form': {
        placeholder: 'Enter your message...',
        'button-label': {
          default: 'Send Message',
          'upload-in-progress': 'Preparing...'
        },
        'rich-text': {
          examples: {
            paragraphs: {
              header: 'Paragraphs',
              'formatted-text': 'Add two new lines to start a new\nparagraph.\n \nThis is a second paragraph.'
            },
            bold: {
              header: 'Bold',
              'formatted-text': 'Use **asterisks** to bold words'
            },
            lists: {
              header: 'Lists',
              'formatted-text': 'Example of a list:\n \n1. First ordered list item\n2. Another item'
            }
          }
        }
      },
      'negotiation-snapshot': {
        header: 'Proposed a Change To Cost and/or Deliverables',
        payment: 'Payment',
        deliverables: {
          header: 'Deliverables',
          'amazon-post': 'Amazon Posts',
          'blog-post': 'Blog Posts',
          'blogs-to-upload': 'Blogs',
          'facebook-post': 'Facebook Posts',
          'instagram-post': 'Instagram Post',
          'instagram-reel-post': 'Instagram Reel Post',
          'instagram-story-post': 'Instagram Story Post',
          'linkedin-post': 'LinkedIn Posts',
          'minimum-child-content-submission-count': 'Min # of Story Frames',
          'mixed-media-to-upload': 'Story Uploads',
          'photos-to-post': '{network} Posts',
          'photos-to-upload': 'Photos',
          'pinterest-post': 'Pinterest Posts',
          'reddit-post': 'Reddit Posts',
          'reels-to-post': 'Instagram Reels Posts',
          'review-post': 'Review Posts',
          'snapchat-post': 'Snapchat Posts',
          'stories-to-post': 'Story Posts',
          'tiktok-post': 'TikTok Posts',
          'twitch-post': 'Twitch Posts',
          'twitter-post': 'Twitter Posts',
          'videos-to-upload': 'Videos',
          'youtube-post': 'YouTube Posts',
          'minimum-number-of-mixed-media': 'Min # of Story Frames'
        },
        footer: {
          'accept-offer': 'Accept Offer',
          'decline-offer': 'Decline Offer'
        },
        'offer-state': {
          pending: 'Offer Received',
          withdrawn: 'Offer Withdrawn',
          rejected: 'Offer Rejected',
          accepted: 'Offer Accepted'
        },
        'confirmation-modal': {
          decline: 'Yes, I want to decline the offer',
          accept: 'I agree and accept the offer',
          'accept-offer': {
            header: 'Accept Offer?',
            body: 'By accepting this Offer, you agree that the proposed deliverable and/or compensation terms included in this Offer will replace those outlined in the Campaign Brief and your application for the Gig, officially amending these terms between you and the Brand. All other terms in the Campaign Brief and your application for the Gig remain unchanged.',
            'applicant-disclaimer': 'Agreeing to the amended terms in this Offer, does not guarantee your selection for the Gig. The Brand will still need to accept your application for the Campaign.'
          },
          'decline-offer': {
            header: 'Decline Offer?',
            body: 'Are you sure you want to decline this offer?'
          }
        }
      },
      'no-auth-form-control': {
        placeholder: 'enter handle...',
        submit: 'Add {network} Account'
      },
      'no-auth-form-modal': {
        tiktok: {
          pre: "Before you add your TikTok account, you must follow <a href='https://www.tiktok.com/@popularpays' target='_blank' rel='noopener noreferrer'>Popular Pays</a> to verify account ownership.",
          post: 'You verify that you own the TikTok handle entered or you have permission to manage the account on behalf of the owner. Impersonating ownership of a TikTok account can lead to a permanent ban from Popular Pays.'
        }
      },
      'not-found': {
        'error-code': '404',
        body: "You don't have to go home, but you can't click here.",
        'go-home': 'Take me home'
      },
      'notifications-form': {
        save: 'Save Changes'
      },
      'password-form': {
        'password-requirements-label': 'We have new password strength requirements, please update your password!',
        'password-requirements-requirements': 'Minimum requirments: 8 characters, 1 Uppercase, 1 lowercase, 1 number, and 1 special character',
        'new-password-label': 'New Password',
        'verify-password-label': 'Retype New Password',
        'tos-1': 'By accessing our Platform, you agree to comply with our',
        'tos-2': 'Privacy Policy',
        'tos-3': 'and ',
        'tos-4': 'Platform Terms and Conditions',
        'confirm-button': 'Update Password',
        errors: {
          'password-missing': 'Slow your roll. ✋ Password, please.',
          'password-mismatch': 'Not a match. Wanna try again?'
        }
      },
      'password-reset-form': {
        buttons: {
          reset: 'Reset Password'
        },
        'email-label': 'Email'
      },
      'payment-type-form': {
        'direct-deposit': {
          'fee-description': 'You will be paid securely direct to your bank account, with no transfer fees.',
          'fee-title': 'Zero Fees',
          'option-label': 'Direct Deposit'
        },
        'payment-label': 'Choose your payment method',
        paypal: {
          'option-label': 'PayPal'
        }
      },
      'payment-fee-block': {
        paypal: {
          title: 'PayPal Fees',
          description: "PayPal's <a href='https://www.paypal.com/us/webapps/mpp/merchant-fees' rel='noopener noreferrer' target='_blank'>merchant fees</a> will be deducted from your payments."
        }
      },
      'policy-update': {
        modal: {
          'accept-tos': 'I agree to the terms and conditions.',
          description: 'When it comes to identifiable people in your photos, we want to make sure we have their permission in any content uploaded. By checking the box below, you agree to provide documentation of their written consent to be included for EVERY PIECE of content you upload.',
          footer: 'Questions? See our <a href="https://popularpays.zendesk.com/hc/en-us" rel="noopener noreferrer" target="_blank">FAQ</a>.',
          title: "We've updated our Privacy Policy recently."
        }
      },
      'profile-form': {
        'avatar-upload-note': 'Ideal profile images are larger than 200x200 px. Max file size is 1 MB.',
        bio: {
          header: 'Bio',
          'sub-header': "This is your chance to tell brands about yourself and the kinds of projects you're looking for.",
          placeholder: 'Tell us about the work you love to do.'
        },
        'birthday-label': 'Birthday',
        'content-preferences': {
          header: 'Capabilities',
          'sub-header': "Only include the content formats that you can be hired to create. If we've missed something, send us a message."
        },
        'creator-type': {
          header: 'I am a',
          options: {
            'content-creator': 'Content Creator',
            influencer: 'Social Media Influencer'
          }
        },
        gender: {
          header: 'Gender',
          'sub-header': 'Brands may search by gender to invite you to Gigs.',
          options: {
            female: {
              text: 'Female'
            },
            male: {
              text: 'Male'
            },
            other: {
              text: 'Other',
              placeholder: 'other...',
              label: 'Other Gender Field'
            },
            na: {
              text: 'Prefer not to say'
            }
          }
        },
        'location-label': 'Location',
        'name-label': 'Name',
        submit: {
          'button-text': 'Save Profile Info',
          'gdpr-copy': "By saving your Profile, you allow us to share your data with brands, agencies, and partners, in accordance with our <a href='https://popularpays.com/terms' target='_blank' rel='noopener noreferrer' class='inline'>Creator Terms of Service</a> and <a href='https://popularpays.com/privacy' target='_blank' rel='noopener noreferrer' class='inline'>Privacy Policy</a>."
        },
        tags: {
          header: 'Specialties',
          'sub-header': 'Select up to {maxSelectable} tags that describe the kind of content you create.'
        }
      },
      'portfolio-progress': {
        breakpoints: {
          0: {
            title: 'Please fill in your details to verify your account.',
            helper: 'Fill this out so we can get to know you.'
          },
          1: {
            title: 'Your account is {percentage}% complete.',
            helper: "We'd like a little more info to match you with gigs."
          },
          2: {
            title: 'Your account is complete!',
            helper: "We'd like a little more info to match you with gigs."
          }
        },
        'link-text': 'What makes a good profile?',
        title: 'Your Account:'
      },
      'shipping-address-form': {
        label: {
          name: 'Full Name',
          street1: 'Address One',
          street2: 'Address Two',
          city: 'City',
          region: 'State/Province',
          postalCode: 'Zip Code',
          country: 'Country'
        },
        placeholder: {
          name: 'First & Last',
          street1: 'Address Line One',
          street2: 'Address Line Two',
          city: 'Your City',
          region: 'Abbreviation',
          postalCode: 'Your Zip',
          country: 'Your Country'
        },
        'address-in-use': "Currently you cannot update or delete this shipping address since it's being used in a gig."
      },
      'social-account': {
        relink: 'Relink',
        'unlink-modal': {
          header: 'Unlink Social Account',
          body: 'Are you sure you want to unlink? Your {network} account will be unlinked.',
          button: 'unlink'
        },
        'unlink-flash-messages': {
          unlinking: 'Unlinking your account...',
          success: 'Successfully Unlinked!'
        }
      },
      'social-media-post': {
        'verified-post': 'We saw your post!'
      },
      tiktok: {
        'handle-checker': {
          controls: {
            input: {
              placeholder: 'Enter your TikTok handle'
            },
            submit: {
              'find-account-label': 'Find Account',
              'request-invite-label': 'Request Invitation to TikTok Creator Marketplace'
            },
            error: {
              'account-not-exist': 'Sorry, that TikTok account does not exist',
              'please-enter-handle': 'Please enter a handle'
            }
          },
          'first-paragraph': {
            'add-tcm-account': "Add your TikTok Creator Marketplace associated account. <a href='https://popularpays.zendesk.com/hc/en-us/articles/4413002121236' target='_blank'>What is the TikTok Creator Marketplace?</a>",
            'not-member': "It looks like the account <b>@{handle}</b> isn't a member of the TikTok Creator Marketplace",
            'invitation-requested': 'Invitation requested, check you TikTok inbox!',
            'already-in-tcm': 'Your account is being processed by TikTok. Please notify support if this becomes a recurring problem.',
            'invitation-denied': 'Your TikTok account does not qualify for the TikTok Creator Marketplace. Please try again when your account meets the requirements.'
          },
          'second-paragraph': {
            'pre-text': 'To join the TikTok Creator Marketplace, you must first submit a request and be accepted by the TikTok team based on the following requirements:',
            requirements: {
              first: '18+ Years Old',
              second: 'Minimum 10k Followers',
              third: 'At least 1k video views in the last 30 days',
              fourth: 'At least 3 posts in the last 30 days',
              fifth: 'Personal Account (Not a Business Account)'
            },
            'post-text': 'Heads up! If your account does not currently meet these requirements, you will not be accepted into the TikTok Creator Marketplace.',
            questions: "Questions? Head to our <a href='https://popularpays.zendesk.com/hc/en-us/categories/360005423812-FAQs' target='_blank'>FAQ page</a>.",
            'post-invite-message': "If you meet the requirements, you should receive a message in your TikTok inbox with an invitation to join the TikTok Creator Marketplace within the next hour. Once you successfully join the Marketplace, you'll be able to link you TikTok Creator Marketplace account on Pop Pays!"
          }
        }
      },
      onboarding: {
        'social-networks': {
          header: 'Link your socials',
          subheader: "The more accounts you link, the more Gigs you'll see.",
          'link-account-message': 'You can add more accounts later in your settings.',
          'proceed-button-text': 'Done >'
        },
        'social-verify': {
          'linking-informed-consent': "When you link a social media account, you authorize Popular Pays to receive information about your account from that social media platform, and to have your information disclosed to brands, agencies, and partners, in accordance with our <a href='https://popularpays.com/terms/' target='_blank'>Creator Terms of Service</a> and <a href='https://popularpays.com/privacy/' target='_blank'>Privacy Policy</a>",
          'support-link': {
            question: 'Having trouble linking your account?',
            answer: 'Check our FAQs for help <a target="_blank" href=\'https://popularpays.zendesk.com/hc/en-us/categories/360002417572-Creator-Experience\'>here</a>.'
          }
        }
      },
      'tracking-info': {
        carrier: {
          ups: 'UPS',
          usps: 'USPS',
          fedex: 'Fedex',
          unknown_carrier: 'Unknown Carrier',
          no_carrier: ''
        },
        'carrier-label': 'Carrier:',
        'tracking-number-label': 'Tracking #:',
        'unknown-carrier-label': 'Tracking Information from {brand}:'
      },
      'x-footer': {
        'get-help-link': 'Get Help',
        'privacy-policy-link': 'Privacy Policy'
      },
      'deliverable-status': {
        shipping: {
          awaiting_fulfillment: {
            header: 'Awaiting Shipping Details',
            body: "We're waiting for the shipping information to send along the product for this gig. Hang tight and we'll update you soon."
          },
          en_route: {
            header: 'In transit',
            body: "The product for this gig is headed your way. Check the tracking info below. Have questions? <a href='https://popularpays.zendesk.com/hc/en-us/articles/360037944011' target='_blank'>Learn more here</a>."
          },
          fulfilled: {
            header: 'Delivered',
            'start-gig-text': "Your package has arrived, and it's time to",
            'start-gig-link': 'get cracking on that gig!',
            'send-message-text': "If your product hasn't actually been delivered,",
            'send-message-link': 'send us a message ASAP.'
          },
          info: {
            header: 'Shipping Info Provided',
            body: "Our system doesn't support tracking this shipment method, but check the tracking info below for updates."
          },
          exception: {
            header: 'Exception',
            body: '<em>Signature not received.</em><br>Send us a message with any questions.'
          }
        },
        'gift-code': {
          awaiting_fulfillment: {
            header: 'Awaiting Gift Code',
            body: "We're waiting on the brand to send the gift code you can use to get the product for this gig. We'll update you soon."
          },
          fulfilled: {
            header: 'Gift Code Ready',
            'send-message-text': "Use the code below to get the product you'll need for your gig. If you aren't sure where to use the code,",
            'send-message-link': 'send us a message in the app.'
          }
        }
      },
      'portfolio-media-item-submission-form': {
        'add-label': 'Add Photo or Video'
      },
      'content-submission': {
        'accepted-state-label': '{kind} is approved',
        feedback: 'Feedback',
        'pending-state-label': shared.defaultStateLabel,
        'processing-state-label': '{kind} is processing. Refresh to see processed photo.',
        'rejected-state-label': 'edit requested',
        'uploading-state-label': shared.defaultStateLabel,
        'archived-state-label': shared.defaultStateLabel
      },
      'feedback-message': {
        at: 'at',
        feedback: 'feedback',
        'approved-text': 'Approved'
      },
      'follower-count': {
        info: 'To make changes to your linked social handles, visit <a href="/account/social-networks">Account > Social Networks</a>'
      },
      'content-submission-dialog': {
        'cancel-button': 'Cancel',
        'header-text': 'Edit Content Submission',
        'please-wait': 'Please wait...',
        'privacy-info': 'GDPR Stuff',
        'submit-button': 'Done'
      },
      'content-submission-discard-changes-dialog': {
        header: 'Discard changes?',
        subtext: 'Your edits and new uploads will be discarded.',
        'discard-button': 'Discard Changes',
        'cancel-button': 'Cancel'
      },
      'content-submission-mixed-media-header': {
        'back-button': {
          accepted: 'Approved',
          default: 'Back',
          pending: 'Pending'
        },
        navigation: {
          edit: 'Edit',
          manage: 'Manage'
        },
        'please-wait': 'Please wait...',
        'submit-button': 'Submit for Review',
        tooltips: {
          'min-submission': 'Submit at least {minUploadCount} content pieces',
          'request-edits': '1 items needs your attention',
          'request-edits-plural': '{pendingChangesCount} items need your attention'
        },
        'upload-button': 'Upload Content'
      },
      'content-submission-mixed-media-submission': {
        at: 'at',
        'edit-button': 'Edit',
        'pending-changes-text': 'Edited'
      },
      'user-toolbar': {
        account: 'Account',
        'your-gigs': 'Your Gigs',
        threads: 'Threads',
        support: 'Support',
        logout: 'Sign out',
        report: 'Report',
        'latest-gigs': 'Suggested Gigs',
        profile: 'Profile'
      },
      'paypal-account-form': {
        label: "Where we'll send your PayPal payment",
        terms: "We need your information to process payments for completed gigs. By clicking \"Save payment method,\" you acknowledge that being paid via PayPal means <a href='https://www.paypal.com/us/webapps/mpp/merchant-fees' rel='noopener noreferrer' target='_blank'>merchant fees</a> will be deducted as a transfer fee."
      },
      'status-with-icon': {
        application: {
          awaiting_preapproval: {
            title: 'Application under review',
            description: 'The brand is still deciding, stay tuned!'
          },
          proposed: {
            title: 'Application under review',
            description: 'The brand is still deciding, stay tuned!'
          },
          cancelled: {
            title: 'Campaign cancelled',
            description: 'Thanks for your application! Unfortunately this campaign has been canceled by the brand.'
          },
          rejected: {
            title: "Thanks for your application, but you weren't selected this time.",
            description: "Review tips on how to <a href='https://popularpays.com/create-application-worth-sharing/' target='_blank' rel='noopener noreferrer'>create a successful application</a>."
          },
          expired: {
            title: 'Application expired',
            description: 'Your application expired before you were accepted or rejected. You can always try reapplying if it shows up in your gigs again.'
          }
        },
        shipping: {
          awaiting_fulfillment: {
            title: 'Awaiting Shipping Details',
            description: "We're waiting for the shipping information to send along the product for this gig. Hang tight and we'll update you soon."
          },
          en_route: {
            title: 'In transit',
            description: "The product for this gig is headed your way. Check the tracking info below. Have questions? <a href='https://popularpays.zendesk.com/hc/en-us/articles/360037944011' target='_blank'>Learn more here</a>."
          },
          fulfilled: {
            title: 'Delivered',
            'start-gig-text': "Your package has arrived, and it's time to",
            'start-gig-link': 'get cracking on that gig!',
            'send-message-text': "If your product hasn't actually been delivered,",
            'send-message-link': 'send us a message ASAP.'
          },
          info: {
            title: 'Shipping Info Provided',
            description: "Our system doesn't support tracking this shipment method, but check the tracking info below for updates."
          },
          exception: {
            title: 'Exception',
            description: '<em>Signature not received.</em><br>Send us a message with any questions.'
          }
        },
        'gift-code': {
          awaiting_fulfillment: {
            title: 'Awaiting Gift Code',
            description: "We're waiting on the brand to send the gift code you can use to get the product for this gig. We'll update you soon."
          },
          fulfilled: {
            title: 'Gift Code Ready',
            'send-message-text': "Use the code below to get the product you'll need for your gig. If you aren't sure where to use the code,",
            'send-message-link': 'send us a message in the app.'
          }
        },
        instagram: {
          awaiting_posts: {
            title: 'Awaiting',
            description: 'As you publish the required content for the Gig, you will start to see your Feed posts appear here. Already posted and not seeing your Posts? Add your posts above.'
          }
        },
        youtube: {
          awaiting_posts: {
            title: 'Awaiting',
            description: 'As you publish the required content for the Gig, you will start to see your Feed posts appear here. Already posted and not seeing your Posts? Add your posts above.'
          }
        },
        pinterest: {
          awaiting_posts: {
            title: 'Awaiting',
            description: 'As you publish the required content for the Gig, you will start to see your Feed posts appear here. Already posted and not seeing your Posts? Add your posts above.'
          }
        },
        tiktok: {
          awaiting_posts: {
            title: 'Awaiting',
            description: 'As you publish the required content for the Gig, you will start to see your Feed posts appear here. Already posted and not seeing your Posts? Add your posts above.'
          }
        },
        'tiktok-creator-marketplace': {
          awaiting_posts: {
            title: 'Awaiting',
            description: 'As you publish the required content for the Gig, you will start to see your Feed posts appear here. Already posted and not seeing your Posts? Add your posts above.'
          }
        },
        'tiktok-v2': {
          awaiting_posts: {
            title: 'Awaiting',
            description: 'Once you have posted through the invite link your post will appear here. Posts can take up to 24 hours to process'
          }
        },
        facebook: {
          awaiting_posts: {
            title: 'Awaiting',
            description: 'As you publish the required content for the Gig, you will start to see your Feed posts appear here. Already posted and not seeing your Posts? Add your posts above.'
          }
        },
        stories: {
          awaiting_stories: {
            title: 'Awaiting',
            description: 'As you publish the required content for the Gig, you will start to see your Stories posts appear here. Already posted and not seeing your Stories? Add your posts above.'
          },
          pending_stories: {
            title: 'In Progress',
            description: "We've seen some of your story posts for the Gig. We'll keep checking for the rest."
          },
          complete_stories: {
            title: 'Complete',
            description: 'We saw all of your story posts for the Gig.'
          }
        },
        reels: {
          awaiting_reels: {
            title: 'Awaiting',
            description: 'As you publish the required content for the Gig, you will start to see your Reels posts appear here. Already posted and not seeing your Reels? Add your posts above.'
          }
        }
      },
      'register-form': {
        'email-label': 'Email',
        'email-placeholder': 'Enter your email',
        'terms-label': "I agree to the <a href='https://popularpays.com/terms' target='_blank' rel='noopener noreferrer' class='inline'>Terms of Service</a> and <a href='https://popularpays.com/privacy' target='_blank' rel='noopener noreferrer' class='inline'>Privacy Policy</a>",
        'submit-text': 'Join now',
        errors: {
          'email-exists': "That email's already registered. Try another?",
          format: 'Please enter your email address',
          empty: 'Please enter your email address',
          default: 'Could not create that user. Hmmm...',
          'not-email': "That doesn't look an email to us. Hit us up at support@popularpays.zendesk.com"
        },
        'brand-safety': {
          headline: 'Turn your creativity into',
          'sub-headline': 'a partnership with',
          'terms-label': 'I agree to the Terms of Service and Privacy Policy',
          join: 'Join now',
          'already-have-account': 'Already have an account?',
          login: 'Log in',
          'confirm-email': 'Confirm Your Email',
          privacy: "By clicking 'Accept', you agree to the <a target='_blank' href='https://static.lightricks.com/legal/popular-pays-platform-terms-and-conditions.pdf' class='color--gray-medium-dark'>Popular Pays Terms</a> and the creator payment terms. <br> Learn about how we use and protect your data in our <a target='_blank' href='https://static.lightricks.com/legal/privacy-policy.pdf' class='color--gray-medium-dark'>Privacy Policy</a>"
        }
      },
      'mixed-media-form': {
        body: 'Add and reorder content pieces to create a Story Upload.<br>A minimum of {minimumChildContentSubmissionCount} Story Frames are required for each upload.',
        'button-label': 'Upload Files',
        'button-label-uploading': 'Uploading...',
        note: 'Must be smaller than 30MB for images and 3GB for videos.',
        title: 'Upload your photos and videos'
      },
      'non-mixed-media-form': {
        'button-label': 'Upload Files',
        'button-label-uploading': 'Uploading...',
        note: 'Must be smaller than 30MB for images and 3GB for videos.',
        title: 'Upload your content for review.',
        body: 'Add content pieces for approval by brand or deliverable posts from other platforms to complete your gig.'
      },
      'upload-disclaimer': {
        'disclaimer-text': 'Content and captions that <strong>have not yet been approved cannot be posted.</strong> You warrant that you have the necessary permissions for any of the content you upload, including but not limited to authorizations for any identifiable individuals, third party brand logos or markers, music, artwork, architecture, etc.'
      },
      'profile-editable-avatar': {
        controls: {
          edit: 'edit'
        },
        errors: {
          size: 'File size must be smaller then 1MB. Your file is {fileSizeInMB}MB',
          dimension: 'File dimensions are greater than 250px x 250px. Selected file {fileWidth}px by {fileHeight}px.'
        }
      },
      'campaign-page': {
        header: {
          buttons: {
            apply: {
              hover: "I've read this brief. Ready to apply!",
              text: 'Apply for this Gig',
              'not-enough-followers': '{followers}+ followers needed',
              'too-many-followers': 'Too many followers',
              'disabled-text': 'Reconnect {network} to apply',
              'not-enough-followers-desktop': '{followerCount}+ followers needed',
              'not-enough-followers-mobile': 'Gig requires at least {followerCount} followers',
              'link-your-socials': 'Link {network} to apply'
            }
          },
          'dismiss-button-text': 'Not Interested'
        }
      },
      'campaign-objective': {
        label: 'campaign fulfillment'
      },
      'post-selector-modal': {
        'no-posts': 'No {socialStream} posts found',
        banner: {
          'before-link': "Still don't see your posts? Click",
          link: 'here',
          'after-link': 'to add your post URLs manually'
        },
        'manual-add-post-url': {
          title: 'Post URL'
        }
      },
      'increase-engagement-banner': {
        LINK_SOCIAL_NETWORK_BANNER: {
          title: 'Get Social To Get More Gigs!',
          subTitle: 'Connect your social media accounts so more brands will want to collab',
          buttonText: 'Connect social account'
        },
        FILL_PROFILE_BANNER: {
          title: 'Fill Your Profile To Get More Gigs!',
          subTitle: 'Complete your profile so more brands will want to collab',
          buttonText: 'Edit profile'
        }
      },
      'legal-social': {
        link: 'Why linking social accounts is important?'
      },
      'legal-social-note': {
        text: 'By connecting your social accounts you allow Brands to see your content style and audience! ' + 'The more social accounts you link, the better brands can get to know you and select you for relevant gigs. ' + "By linking a social account, you agree to our <a href='https://popularpays.com/privacy/' target='_blank'>Privacy Policy</a>"
      },
      'report-inappropriate-content': {
        'title-report-content': 'Report Inappropriate Content',
        'title-report-mobile': 'Report',
        'title-report-url': 'Copy & paste the URL of the content you want to report.',
        'subtitle-report-content': 'Why are you reporting this?',
        'report-done': {
          title: 'Thanks for letting us know',
          subtitle: 'Your feedback helps us keep the community happy and safe.'
        },
        'report-content-email-message': 'Hello,\nI wanted to report inappropriate content I came across at the following URL:{url}\nThe type of report is: {typeReport}',
        'report-content-email-subject': 'Report inappropriate content'
      },
      'modal-terms-of-service': {
        h1: 'Our Terms of Service have changed',
        p: "By clicking 'Accept', you agree to the <a target='_blank' href='https://static.lightricks.com/legal/popular-pays-platform-terms-and-conditions.pdf' class='color--gray-medium-dark'>Popular Pays Terms</a> and the creator payment terms. <br> Learn about how we use and protect your data in our <a target='_blank' href='https://static.lightricks.com/legal/privacy-policy.pdf' class='color--gray-medium-dark'>Privacy Policy</a>",
        'button-text': 'Accept'
      },
      'brand-safety': {
        'sign-up': {
          'select-platforms': {
            headline: 'Which social channels are you on?',
            next: 'Next'
          },
          'sync-platforms': {
            default: 'Your data is secure. It’s used to confirm your account and check basic stats.',
            'two-platforms': 'Connect one more social to boost your partnership chances by 57%.',
            'three-platforms': 'Maximize your odds by connecting {social} now.',
            heading: 'Sync socials to apply',
            progress: '{progress}% completion'
          },
          'unfinished-flow': {
            'almost-there': 'You’re almost there!',
            'almost-there-description': 'You’ll get an email soon, so you can finish syncing your socials.',
            'take-me-back': 'Take me back',
            'connect-last-social': 'Connect your last social account to complete your application for this partnership.',
            'dont-miss-out': 'Don’t miss out on working with {brandName}!',
            'connect-socials': 'Connect your socials to increase your odds of being selected.',
            'need-help': 'Need help?',
            guidance: 'We’re here to guide you',
            'finish-connecting': 'Finish connecting',
            'maybe-later': 'Maybe later',
            'connect-platform': 'Connect {platform}'
          },
          'finished-flow': {
            'all-set': 'All set!',
            'in-the-running': 'You’ve successfully applied for the {brandName} partnership.',
            'account-live': 'Your account is active and ready to connect with top brands.',
            'check-email': 'Check your email for next steps and to discover more paid partnerships',
            'discover-more-campaigns': 'Discover more campaigns'
          },
          'instagram-instructions': {
            skip: 'Skip',
            headline: 'Login to Instagram via Facebook',
            instruction: 'Sign into your Facebook Business Account and then choose the Instagram account you want to sync.',
            info: 'Learn how to convert to Business or Creator account.',
            'follow-these-steps': 'Follow these steps',
            continue: 'Continue with Facebook'
          },
          'platform-list': {
            email: 'Email',
            'coming-soon': 'Coming soon'
          }
        }
      }
    },
    models: {
      'content-submission': {
        blog: {
          'attachment-label': 'Screenshot or PDF of Blog',
          body: 'Upload your blog as a PDF or a screenshot and paste the text of the blog content. The Brand will get in touch if they have any notes.',
          'button-label': 'Upload a blog',
          'caption-label': 'Blog Content',
          'caption-placeholder': 'Enter your proposed blog content here.',
          note: '',
          title: 'Upload your blog'
        },
        mixed_media: {
          'caption-placeholder': 'Enter notes for the brand here. If this is an Instagram story, include all required hashtags and mentions.'
        },
        photo: {
          'attachment-label': 'Attachment',
          body: 'Time to double-check that Gig brief. The Brand will review the content you upload & let you know if they have any notes.',
          'button-label': 'Upload a photo',
          'caption-label': 'Caption',
          'caption-placeholder': 'Enter notes for the brand or your proposed caption here (if applicable).',
          note: '',
          title: 'Upload your photos with captions'
        },
        post: {
          'attachment-label': 'Screenshot',
          body: 'Include a screenshot with a link to the post',
          'button-label': 'Upload file',
          'caption-label': 'Notes',
          'caption-placeholder': 'Enter a post URL and any additional info for the Brand.',
          note: 'Max file size is 30MB',
          title: 'Upload {network} post screenshot'
        },
        video: {
          'attachment-label': 'Attachment',
          body: 'Time to double-check that Gig brief. The Brand will review the content you upload & let you know if they have any notes.',
          'button-label': 'Upload a video',
          'caption-label': 'Caption',
          'caption-placeholder': 'Enter notes for the brand or your proposed caption here (if applicable).',
          note: 'Max file size is 3GB.',
          title: 'Upload your videos with captions'
        }
      },
      profile: {
        'unsaved-changes': "Make sure you save your profile updates before leaving this page. By clicking OK, you'll lose all unsaved changes."
      },
      user: {
        deactivate: {
          success: 'Account was successfully removed from our system. You will now be signed out.',
          failure: 'We were unable to delete your account at this time. Please try again later or contact us at support@popularpays.zendesk.com'
        },
        delete: {
          success: 'Account was successfully removed from our system. You will now be signed out.',
          failure: 'We were unable to delete your account at this time. Please try again later or contact us at support@popularpays.zendesk.com'
        }
      }
    },
    services: {
      fortress: {
        errors: {
          'generate-otp': {
            default: "The email wasn't sent. An unknown error occurred. Please try again",
            400: "The email wasn't sent. An unknown error occurred. Please try again",
            429: 'Too many attempts. Please try again in ten minutes.',
            409: 'Something went wrong. Please <a href="https://popularpays.zendesk.com/hc/en-us/requests/new" rel="noopener noreferrer" target="_blank">contact us</a> to access your account.'
          },
          'verify-otp': {
            default: 'Verification failed. Please wait a few minutes before you try again',
            401: 'Code not recognised. Please try again or request a new one.',
            400: 'Please request a new code and try again.',
            409: 'Something went wrong. Please <a href="https://popularpays.zendesk.com/hc/en-us/requests/new" rel="noopener noreferrer" target="_blank">contact us</a> to access your account.'
          }
        }
      }
    },
    templates: {
      auth: {
        'login-text': 'Sign in',
        'join-text': 'Join',
        'password-reset': {
          'reset-text': 'Reset your password',
          'check-your-email': "Check your email for your password reset link. If it's not in your inbox, please check your spam folder."
        },
        login: {
          title: 'Creators Log in',
          'welcome-message': 'Welcome back, friend',
          'submit-label': 'Log In',
          'note--terms': {
            text: 'By clicking Log in, you acknowledge that you have read and agree to our ',
            'text-link': 'Creator Terms and Conditions of Service',
            'link-term': 'https://popularpays.com/terms'
          },
          'note--sign-up': {
            text: "Don't have an account ? ",
            'text-link': 'Sign up',
            'link-term': '/auth/register'
          },
          errors: {
            default: 'Please enter your email address',
            suspended: "Something about your account set off our system's alarms, and we're looking into it. We make mistakes sometimes too, so if you can clear up what's going on, please reach out to us at support@popularpays.zendesk.com"
          }
        },
        register: {
          title: 'Join our community of creators',
          subheader: "You're already making amazing content.<br>Why not get paid for it?",
          'subheader-lines': {
            'first-line': "You're already making amazing content.",
            'second-line': 'Why not get paid for it?'
          },
          'welcome-message': {
            'first-line': "You're already making amazing content.",
            'second-line': 'Why not get paid for it?'
          },
          'note--login': {
            text: 'Already have an account ? ',
            'text-link': 'Log in',
            'link-term': '/auth/login'
          },
          errors: {
            default: "That email's already registered. Try another?"
          }
        }
      },
      protected: {
        verified: {
          banners: {
            'portfolio-progress': {
              'link-copy': 'your profile.',
              message: 'Time for an update. We added new things to'
            },
            'needs-link-payment-processor': {
              'link-copy': 'Add a payment method.',
              message: "You completed a gig, but we don't know how to pay you!"
            },
            'maintenance-mode': {
              message: "We are currently in maintenance mode, some features may be disabled. We'll be back soon!"
            }
          },
          collaborations: {
            'page-title': 'Threads',
            header: 'Threads',
            'creator-filter': {
              'placeholder-text': 'Search creators'
            },
            filtered: {
              'no-results-text': 'No threads found.'
            },
            'unread-filter': {
              'all-option': 'All Threads',
              'unread-option': 'Unread'
            },
            'show-more': 'Load More',
            'user-disabled-error': 'This creator has requested to be removed from our system and can no longer receive messages.',
            collaboration: {
              'collaboration-title': 'Discussion with <strong>{displayName}</strong>'
            },
            'empty-result': {
              header: 'No threads found',
              'body-prefix': 'To initiate a Thread connect with a creator from',
              'body-conjunction': 'or your',
              'search-link': 'Search',
              'list-link': 'List'
            }
          },
          profile: {
            'page-title': 'Profile',
            'profile-description': 'Filling out your profile helps us serve you Gigs that are just right for you.',
            'portfolio-description': 'Showcase 4-18 examples of your content, including different styles, capabilities and/or branded content. Upload files in the order you want them displayed. You acknowledge and agree that you have all necessary permissions to upload the content to your Popular Pays profile, including consent needed from any third parties and/or identifiable people.',
            'portfolio-save-success': 'Portfolio item was successfully saved.',
            'portfolio-remove-success': 'Portfolio item was successfully removed.',
            'photo-consent': {
              title: 'Notice on uploaded content',
              body: 'By checking this box, you are certifying that you have written consents for all subjects in this content and all content uploaded in the future, and agree to provide it upon request.',
              'terms-label': 'I agree to the terms above.',
              button: 'Done'
            },
            share: 'Share',
            'share-success': 'Copied public url to clipboard'
          },
          myPortfolio: {
            'page-title': 'My Portfolio'
          },
          gigs: {
            'page-title': 'Gigs',
            gig: {
              activity: {
                'content-submission': {
                  filter: {
                    approved: 'approved',
                    declined: 'edits requested',
                    label: 'Show',
                    pending: 'pending',
                    archived: 'archived'
                  }
                },
                posts: {
                  'content-type-text': 'Content {contentType}',
                  'scan-network-text': 'As you publish the required content for the Gig, you will start to see your Feed posts appear here. Already posted and not seeing your Posts? Add your posts above.',
                  'tiktok-v2': {
                    header1: 'Your approved link:',
                    header2: "Don't post without an approved link.",
                    p1: "Whenever you're ready, scan the QR code with your phone and post your approved creation in the TikTok app.",
                    p2: 'To get one, just submit your content for review.',
                    p3: 'Once it’s been approved, your link will appear here.',
                    'copy-button': 'Copy Link'
                  }
                },
                'photo-consent': {
                  title: 'Notice on uploaded content',
                  body: 'By checking this box, you are certifying that you have written consents for all subjects in this content and all content uploaded in the future, and agree to provide it upon request.',
                  'terms-label': 'I agree to the terms above.',
                  button: 'Done'
                },
                'mixed-media': {
                  'move-success': 'Order was updated successfully',
                  'save-failure': 'Save failed. Please try again or contact us at support@popularpays.zendesk.com',
                  'save-success': 'Submission was successfully saved',
                  'select-file-error': 'The files could not be uploaded. Please contact support@popularpays.zendesk.com'
                },
                'non-mixed-media': {
                  'save-success': 'Submission(s) successfully submitted. Refresh page to see updates.',
                  'save-failure': 'Save failed. {failReason}'
                },
                'manual-swap-match': {
                  'instagram-posts': 'Instagram Posts',
                  'facebook-posts': 'Facebook Posts',
                  'youtube-posts': 'Youtube Posts',
                  'tiktok-posts': 'TikTok Posts',
                  'tiktok-creator-marketplace-posts': 'TikTok Posts',
                  'pinterest-posts': 'Pinterest Posts',
                  'instagram-reels': 'Instagram Reels',
                  'instagram-stories': 'Instagram Stories',
                  'post-sub-title': "Posted but not seeing it here? Add posts that weren't automatically captured to complete the post requirements",
                  'reels-sub-title': "Posted but not seeing it here? Add reels that weren't automatically captured to complete the post requirements",
                  'story-sub-title': "Posted but not seeing it here? Add stories that weren't automatically captured to complete the stories requirements",
                  'add-feed-posts': 'Add Feed Posts',
                  'add-reels-posts': 'Add Reels Posts',
                  'add-story-posts': 'Add Stories Posts',
                  'view-post': 'View Post',
                  'add-posts': 'Add Posts',
                  'selected-posts': 'Selected Posts',
                  'successfully-added-swap-match': 'Your posts are processing! Please check back later to see them added here',
                  'error-adding-swap-match': 'There was an error adding your post: {error}',
                  'mark-stories-complete': 'Mark Stories Posts Complete',
                  'story-posts-marked-complete': 'Stories posts have been marked as complete',
                  'story-posts-marked-complete-error': 'There was an error marking your stories posts as complete',
                  warning: "Failure to comply with the brief can result in a denial of payment, payment penalties, needing to return payment to Popular Pays and/or suspension from the platform as per the <a href='https://popularpays.com/terms' target='_blank' rel='noopener noreferrer'>Terms of Service</a>"
                },
                stories: {
                  'we-saw-content': 'We saw this content from your stories',
                  'modal-body1': 'Finished with your stories posts? Once you have completed all the requirements for Instagram Stories as outlined in the brief, you can mark them as complete. By marking them as complete, you represent and warrant that you have posted all Stories post requirements per the terms of the brief. Not sure?',
                  'check-out-the-brief': 'Check out the brief',
                  'modal-body2': 'to confirm',
                  'modal-body-penalty': "Failure to comply with the brief can result in a denial of payment, payment penalties, needing to return payment to Popular Pays and/or suspension from the platform as per the <a href='https://popularpays.com/terms' target='_blank' rel='noopener noreferrer'>Terms of Service</a>",
                  'mark-story-posts-complete': 'Mark Stories Posts Complete',
                  'completed-story-posts-button': 'I have completed all {requiredStoryPostsCount} Stories post requirements',
                  'not-finished': "I'm not finished",
                  'story-posts-complete': 'Stories Posts Complete'
                }
              },
              'gigs-link': 'Your Gigs',
              negotiation: {
                notifications: {
                  accept: 'Your Offer acceptance is processing. Please refresh the page later to see the updated terms.',
                  decline: "You've successfully declined the offer",
                  error: 'Uh oh! Please check the status of your current offer by refreshing the page and try again.'
                }
              }
            },
            index: {
              'empty-list-title': 'Nothing to see here yet.',
              'empty-list-body': "This is where you'd see updates for active gigs.",
              header: 'Your Gigs',
              'page-title': 'Activity',
              tabs: {
                active: 'Active',
                inactive: 'Past',
                title: 'Show'
              }
            },
            'your-gigs': {
              'empty-list-body': {
                archive: "This is where you'd see rejected, expired and cancelled gigs.",
                active: "This is where you'd see active gigs.",
                invites: "This is where you'd see invites to gigs.",
                pending: "This is where you'd see pending gigs.",
                completed: "This is where you'd see completed gigs."
              }
            }
          },
          campaigns: {
            networks: {
              'all-invites': 'All Invites',
              heading: 'My Invites',
              network: {
                connect: {
                  'connect-link': 'Account Settings',
                  'connect-text': 'To browse current Gigs, connect your {name} account in',
                  'page-title': 'Connect to {network}'
                }
              },
              'no-network': {
                'page-title': 'Content Creation'
              },
              index: {
                modal: {
                  title: 'Congrats!',
                  subtitle: "You now have a Popular Pays account, here's your login:",
                  terms: "By continuing, you will be connected to Popular Pays' website, and any use of it will be subject to and governed by the Popular Pays' <a href='https://popularpays.com/terms' target='_blank' rel='noopener noreferrer' class='inline'>Creator Terms and Conditions</a> of Service. You acknowledge that you have read and agree to the above terms of service, and other terms and conditions of the Popular Pays website. Lightricks' Privacy Policy shall continue to govern",
                  button: 'Continue'
                }
              }
            },
            'page-title': 'Campaigns',
            'successful-dismiss-text': 'Campaign has been dismissed.',
            campaign: {
              apply: {
                'application-error-text': "Um, that didn't work. Sorry about that. We just alerted our tech team though, and they're on it!",
                'closed-to-proposals-error-text': 'Sorry, this campaign is no longer accepting proposals at this time. Try applying for a different campaign.',
                'outdated-question-error-text': 'Our bad! It seems as though the campaign data has changed since your page has been open. Please refresh your browser and submit again.',
                'need-to-link-social-account-of-campaign': 'You must link {campaignSocialNetworkName} to apply for this campaign.',
                'page-title': 'Apply'
              },
              'dismiss-modal': {
                title: 'Not Interested?',
                description: "Once you dismiss this gig, you won't be able to view it again.",
                'confirm-button': 'Confirm',
                'cancel-button': 'Cancel'
              }
            }
          },
          account: {
            banking: {
              description: 'Make sure your payment method details are correct to keep your payments coming on time.',
              new: {
                location: {
                  label: 'Location',
                  other: 'Outside of US',
                  us: 'US'
                },
                'payment-note': 'Right now, our direct deposit system only supports bank accounts based in the US. Your payments will be sent securely via PayPal.',
                'save-error': 'Shoot! An error occurred and we cannot save that bank account.'
              },
              'page-title': 'Payment Method',
              show: {
                'add-payment-method': 'Add Payment Method'
              }
            },
            description: 'Link your social accounts so brands can get to know you better.',
            'email-password': {
              'account-description': 'You can choose to deactivate your account or delete your account entirely.',
              'account-title': 'Manage Account'
            },
            header: 'My Account',
            'info-blocks': {
              instagram: {
                body: 'Sign in with Facebook to connect your Instagram Business or Creator account.',
                title: 'Reconnect your Instagram Account:'
              },
              pinterest: {
                body: 'Sometimes Pinterest makes changes and, when that happens, we need you to relink your Pinterest account to continue to see gigs.',
                title: 'Reconnect your Pinterest Account:'
              }
            },
            'manage-account': {
              'page-title': 'Manage Account',
              'download-success': 'Success! Check your email for additional data.'
            },
            navigation: {
              section: {
                'first-title': 'Personal Details',
                'second-title': 'Account Settings'
              },
              'missing-info': {
                profile: 'Update details',
                'my-portfolio': 'Should include at least 4 photos or videos works',
                'social-networks': 'Should include at least one connected account',
                banking: 'Update details to get paid on time',
                'shipping-address': 'Update details so brands can ship you products'
              },
              profile: 'Profile',
              'my-portfolio': 'My Portfolio',
              banking: 'Payment Method',
              'email-password': 'Email & Password',
              'shipping-address': 'Shipping Address',
              'manage-account': 'Manage Account',
              notifications: 'Notifications',
              'social-networks': 'Social Networks'
            },
            notifications: {
              'page-title': 'Notifications',
              'sub-title': 'Email Notifications',
              description: "We'll only send you the emails you want to receive."
            },
            'page-title': 'Account',
            'shipping-address': {
              'page-title': 'Shipping Address',
              'page-description': 'Keep your shipping address current for campaigns that might require shipping products.',
              show: {
                'no-saved-addresses': "You don't have any shipping addresses yet!",
                'add-shipping-address': 'Add Shipping Address'
              },
              new: {
                'save-error': 'Shoot! An error occurred and we cannot save your shipping address.'
              },
              edit: {
                'delete-error': 'Shoot! An error occurred and we cannot delete your shipping address.',
                'delete-success': 'Success! Your shipping address was removed.',
                'update-error': 'Shoot! An error occurred and we cannot update your shipping address.'
              }
            },
            'social-networks': {
              'page-title': 'Social Networks'
            },
            title: 'Social Networks'
          },
          'associate-account-dialog': {
            title: 'Associate this account with {name}?',
            subheader: '{name} added you, do you want to communicate with them on this account?',
            link: 'Yes, link to this account',
            logout: 'No, I need to sign into or create a different account'
          }
        },
        terms: {
          title: "We've updated our Terms of Service",
          body: "We've made some changes to our <a href='https://popularpays.com/terms' target='_blank' rel='noopener noreferrer' class='inline'>Terms of Service</a> & <a href='https://popularpays.com/privacy' target='_blank' rel='noopener noreferrer' class='inline'>Privacy Policy</a> and wanted to give you a heads up.",
          'terms-label': "I acknowledge that I've read and agree to the new Terms of Service.",
          button: 'Done'
        }
      },
      'otp-verification-modal': {
        title: 'A code was sent to your email',
        'brand-safety-title': 'Confirm your email',
        'brand-safety-email-label': 'Enter the verification code we emailed to:',
        'email-label': 'Enter it below to verify this address:',
        'resend-code': 'Resend code',
        'resend-code-success': 'Code was sent to your email.',
        continue: 'Continue'
      }
    },
    'campaign-preview': {
      title: 'Campaign Preview',
      mobile: 'Mobile',
      desktop: 'Desktop'
    },
    'campaign-invite': {
      title: "You've been invited to apply!",
      'button-title': 'Sign Up and Apply',
      'button-title-closed': 'Gig Closed to Applications',
      'campaign-closed': {
        title: 'This gig is closed to applications',
        subtitle: "You can still sign up for Popular Pays to see other gigs you're eligible for.",
        'authenticate-button': 'Sign Up'
      }
    },
    'meta-tags': {
      login: {
        title: 'Creators Log in',
        description: 'Creators sign in here to the Popular Pays platform to access your campaigns.'
      },
      register: {
        title: 'Creator Sign Up',
        description: 'Creators sign up here to access the Popular Pays platform and to get paid for your content.'
      }
    },
    'your-gigs': {
      'nav-bar': {
        invites: 'Invites',
        pending: 'Pending',
        active: 'Active',
        completed: 'Completed',
        archive: 'Archive'
      }
    }
  };
  _exports.default = _default;
});